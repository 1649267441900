<template>
<div id="gridTension">
    <ul class="cards">
        <li v-for="TensionCards, TensionCard in TensionCards" :key="TensionCard">
            <a class="card">
            <img :src="TensionCards.image" class="card__image" alt="" />
            <div class="card__overlay">
                <div class="card__header">
                <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
                <img class="card__thumb" src="images/SIIELEClogo.png" alt="" />
                <div class="card__header-text">
                    <h3 class="card__title">{{TensionCards.title}}</h3>
                </div>
                </div>
                <p class="card__description">{{TensionCards.description}}</p>
            </div>
            </a>      
        </li> 
    </ul>
</div>
</template>

<script>
export default {
    name: 'gridTension',
    data () {
        return {
            TensionCards: [
                {
                image:"./images/workImages/Photo5.jpg",
                title: "SIIELEC",
                description:"Instalaciones residenciales y gestiones CFE",
                },
                {
                image:"./images/workImages/Photo6.jpg",
                title: "SIIELEC",
                description:"Gestiones e instalaciones",
                },
            ],
            
        }
    },
}
</script>

<style>

</style>