import Vue from 'vue'
import App from './App.vue'
import gridIndustrial from './components/gridIndustrial.vue'
import gridComercial from './components/gridComercial.vue'
import gridTension from './components/gridTension.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

new Vue({
  render: h => h(gridIndustrial),
}).$mount('#gridindustrial')

new Vue({
  render: h => h(gridComercial),
}).$mount('#gridcomercial')

new Vue({
  render: h => h(gridTension),
}).$mount('#gridtension')