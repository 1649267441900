<template>
<div id="gridIndustrial">
    <ul class="cards">
        <li v-for="industrialCards, industrialCard in industrialCards" :key="industrialCard">
            <a class="card">
            <img :src="industrialCards.image" class="card__image" alt="" />
            <div class="card__overlay">
                <div class="card__header">
                <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
                <img class="card__thumb" src="images/SIIELEClogo.png" alt="" />
                <div class="card__header-text">
                    <h3 class="card__title">{{industrialCards.title}}</h3>
                </div>
                </div>
                <p class="card__description">{{industrialCards.description}}</p>
            </div>
            </a>      
        </li> 
    </ul>
</div>
</template>

<script>
export default {
    name: 'gridIndustrial',
    data () {
        return {
            industrialCards: [
                {
                image:"images/workImages/Photo3.jpg",
                title: "SIIELEC",
                description:"Instalaciones industriales",
                },
                {
                image:"images/workImages/Photo4.jpg",
                title: "SIIELEC",
                description:"Instalaciones industriales",
                },
                {
                image:"images/workImages/Photo7.jpg",
                title: "SIIELEC",
                description:"Automatización y control",
                },
            ],
            
        }
    },
}
</script>

<style>

</style>