<template>
<div id="gridComercial">
    <ul class="cards">
        <li v-for="comercialCards, comercialCard in comercialCards" :key="comercialCard">
            <a class="card">
            <img :src="comercialCards.image" class="card__image" alt="" />
            <div class="card__overlay">
                <div class="card__header">
                <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
                <img class="card__thumb" src="images/SIIELEClogo.png" alt="" />
                <div class="card__header-text">
                    <h3 class="card__title">{{comercialCards.title}}</h3>
                </div>
                </div>
                <p class="card__description">{{comercialCards.description}}</p>
            </div>
            </a>      
        </li> 
    </ul>
</div>
</template>

<script>
export default {
    name: 'gridComercial',
    data () {
        return {
            comercialCards: [
                {
                image:"./images/workImages/Photo8.jpg",
                title: "SIIELEC",
                description:"Instalación de charolas y cableado",
                },
                {
                image:"./images/workImages/Photo9.jpg",
                title: "SIIELEC",
                description:"Instalación de cableado",
                },
            ],
            
        }
    },
}
</script>

<style>

</style>